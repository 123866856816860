import React, {useState, useEffect} from 'react'
import Logo from '../Logo/Logo'
import Title from '../Title/Title'
import UserAvatar from '../UserAvatar/UserAvatar'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ResetPasswordDialog from "../../pages/Auth/ResetPasswordDialog";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      color: '#ffffff',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: '#ffffff',
    },
    title: {
      flexGrow: 1,
      textAlign: 'center',
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
      logoutButton:{
        color: '#ffffff',
        textTransform: 'Capitalize',
      }
  }));

const Header = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userdetails, setUserDetails] = useState(null);
    const [changePassword, setChangePassword] = React.useState(false);

    useEffect(() => {
    const sessionUser = localStorage.getItem('session_user');

    if (sessionUser) {
      setUserDetails(sessionUser);
    }

  }, []);

  const logout = () => {
    localStorage.clear();
    window.location.href='/';
  }
 
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    return (
        <div>
            <AppBar position="static" color="secondary" className={classes.root}>
                <Toolbar>
                    <Logo />  
                    <Typography variant="h6" className={classes.title}>
                        <Title />
                    </Typography>
                    
                    {userdetails && <>
                      <UserAvatar/> 
                      <Typography variant="h6" >
                      <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        style={{color:"WHITE"}}
                      >
                        {userdetails ? userdetails : ''}
                      </Button>
                      
                      </Typography>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                          <MenuItem onClick={() => {setChangePassword(true)}}>Change Password</MenuItem>
                          <MenuItem onClick={logout}>Logout</MenuItem>
                      </Menu>
                      {changePassword && (
                          <ResetPasswordDialog
                              snackbarShowMessage={props.snackbarShowMessage}
                              changePassword = {changePassword}
                              setChangePassword = {setChangePassword}
                          />
                      )}
                    </>}
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default withAlertSnackBar(Header)
