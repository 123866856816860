import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import constants from "../../Utils/Constants";
import {FormError} from "../../components/FormPage/FormValidationError";
import { withAlertSnackBar } from "../../HOComponents/AlertSnackBarHOC";
import Logo from "../../components/Logo/Logo";
import Title from "../../components/Title/Title";
import CheckIcon from "@material-ui/icons/Check";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { UserServices } from "../../Services/users";
import InputLabel from "../../components/FormPage/InputLabel";
import InputText from "../../components/FormPage/InputText";
import ForgetPasswordDialog from "./ForgetPasswordDialog";
import ResetPasswordDialog from "./ResetPasswordDialog";
import {validatePattern} from "../../Utils/Validator";
import {isMENACountry} from "../../Utils/CountryUtil";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" >
                {constants.SITE.TITLE}
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
            <br></br>
            <small>
                <b>({process.env.REACT_APP_VERSION})</b>
            </small>
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: "59rem",
        width: "100vw",
        //backgroundColor: '#fbedd5'
    },
    image: {
        backgroundColor: "#232f3e",
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "#ffffff",
    },
    welcome: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color:"white"
    },
    paper: {
        margin: theme.spacing(12, 6),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100vw", // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SignIn(props) {
    const classes = useStyles();
    const [formData, setFormData] = React.useState({});
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const [isResetPasswordRequired, setIsResetPasswordRequired] = React.useState(false);
    const [isForgotPasswordRequired, setIsForgotPasswordRequired] = React.useState(false);

    const cognitoSignIn = async () => {
        setIsFormSubmitted(true);
        if (validateForm()) {
            let body = {
                userName: formData.userName ? formData.userName : "",
                password: formData.password ? formData.password : "",
            };
            await UserServices.signIn(body)
                .then((data)=>{
                    const userData = data.data;
                    if(userData.challengeName === "NEW_PASSWORD_REQUIRED") {
                        setIsResetPasswordRequired(true);
                    }
                    else if(userData.login){
                        setLoginAndRedirect(userData)
                        props.snackbarShowMessage(`${constants.AUTH.SIGNIN_SUCCESS}`);
                    }else{
                        props.snackbarShowMessage(`${constants.AUTH.SIGNIN_FAILED}`,"error");
                    }
                })
                .catch((err)=>{
                    props.snackbarShowMessage(`${constants.AUTH.SIGNIN_FAILED}`,"error");
                })
        }
    };

    const onKeyDownSignIn = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter' || event.code === "NumpadEnter") {
            event.preventDefault();
            event.stopPropagation();
            cognitoSignIn();
        }
    }

    const moveFocusToPassword = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter' || event.code === "NumpadEnter") {
            event.preventDefault();
            event.stopPropagation();
            document.getElementById("password").focus();
        }
    }
    const setLoginAndRedirect = async (userData) => {
        try {
                localStorage.setItem("session_user", userData.userName.split('@')[0]);
                localStorage.setItem("accesstoken", userData.accesstoken);
                localStorage.setItem("idtoken", userData.idtoken);
                localStorage.setItem("refreshtoken", userData.refreshtoken);
                localStorage.setItem("candidateId", userData.candidateId)
                localStorage.setItem("emailId", userData.userName)

                await UserServices.getDMSFlag()
                    .then(res =>{
                        localStorage.setItem("IS_DMS_ENABLED",res.data)
                    })
                    .catch(err => {
                        localStorage.setItem("IS_DMS_ENABLED","false")
                    })

                props.snackbarShowMessage(`${constants.AUTH.SIGNIN_SUCCESS}`);
                if(localStorage.getItem("location")){
                    window.setTimeout(() => {
                        window.location.href = localStorage.getItem("location");
                    }, 1000);                }
                else{
                    window.setTimeout(() => {
                        window.location.href = `${constants.DASHBOARD.ROUTE}`;
                    }, 1000);
                }
        } catch(error)
        {
            props.snackbarShowMessage(`${constants.AUTH.SIGNIN_FAILED}`);
        }
    };

    const validateForm = () => {
        let isFormValid = true;
        if (!formData.userName || !formData.password
            || !validatePattern(new RegExp(constants.PATTERN.EMAIL_ADDRESS_PATTERN), formData.userName)) isFormValid = false;
        return isFormValid;
    };
    const handleText = (e) => {
        let formDataL = { ...formData };
        formDataL[e.target.name] = e.target.value;
        setFormData(formDataL);
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={12} sm={6} md={5} lg={6} xl={6} className={classes.image} >
                <div className={`welcome_screen ${classes.welcome}`} style={{ paddingTop: "7rem" }}>
                    <Typography component="h5" variant="h4">
                        Welcome to
                    </Typography>
                    <Logo />
                    <Typography component="h2" variant="h2">
                        <Title />
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} elevation={6} square >
                <div className={classes.paper} style={{marginTop:"2rem"}}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign In
                    </Typography>
                    {(
                        <>
                            <Typography component="p" variant="p">
                                Please enter your Username and Password to Sign In
                            </Typography>

                            <form className={"form reports_view"} noValidate >

                                <Grid container spacing={3} justify="space-around" alignItems="center" >
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form_group_item_report" p={3} style={{ marginTop: "15%" }}
                                    >
                                        <InputLabel
                                            style={{ textAlign: "left" }}
                                            Title="Username"
                                        />
                                        <InputText
                                            style={{ padding: "0.7rem" }}
                                            error
                                            name="userName"
                                            value={formData?.userName}
                                            onChange={handleText}
                                            onKeyDown={moveFocusToPassword}
                                            FieldPlaceHolder="e.g. example@email.com"
                                        />

                                        <p>
                                            {isFormSubmitted && !formData.userName && (
                                                <FormError error={'Username is required'} />
                                            )}
                                            {isFormSubmitted && formData.userName && !validatePattern(new RegExp(constants.PATTERN.EMAIL_ADDRESS_PATTERN), formData.userName) && (
                                                <FormError error={constants.ERROR.INVALID_USERNAME} />
                                            )}
                                        </p>

                                        <div style={{ marginTop: "10%" }} />

                                        <InputLabel
                                            style={{ textAlign: "left"}}
                                            Title="Password"
                                        />
                                        <InputText
                                            style={{ padding: "0.7rem" }}
                                            error
                                            type = "password"
                                            name="password"
                                            value={formData?.password}
                                            onChange={handleText}
                                            onKeyDown={onKeyDownSignIn}
                                            FieldPlaceHolder="e.g. XXXXXXXXX"
                                        />

                                        <p>
                                            {isFormSubmitted && !formData.password && (
                                                <FormError error={'Password is required'} />
                                            )}
                                        </p>


                                    </Grid>
                                </Grid>
                                <Grid container alignItems="top" >
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <Grid container alignItems="center" className="form_group_item" style={{ marginTop: "2rem", marginBottom: "1rem"}}>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <p>
                                                    <Link color="inherit" onClick={() => {setIsForgotPasswordRequired(true)}} style = {{marginTop: '2rem'}}>
                                                        {"Forgot password?"}
                                                    </Link>
                                                </p>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <Grid container alignItems="center" className="form_group_item" style={{ marginTop: "1rem", marginBottom: "1rem", marginLeft: "1rem"}}>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <ButtonPrimary
                                                    Title="Sign In"
                                                    onClick={cognitoSignIn}
                                                    size = "small"
                                                    ButtonIcon={<CheckIcon />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                {isResetPasswordRequired && (
                                    <ResetPasswordDialog
                                        formData = {formData}
                                        setIsResetPasswordRequired = {setIsResetPasswordRequired}
                                        setIsFormSubmitted = {setIsFormSubmitted}
                                        snackbarShowMessage={props.snackbarShowMessage}
                                    />
                                )}
                                {isForgotPasswordRequired && (
                                    <ForgetPasswordDialog
                                        formData = {formData}
                                        setIsForgotPasswordRequired = {setIsForgotPasswordRequired}
                                        setIsFormSubmitted = {setIsFormSubmitted}
                                        snackbarShowMessage={props.snackbarShowMessage}
                                    />
                                )}
                            </form>
                        </>
                    )}
                </div>
                {
                    isMENACountry() && <Box>
                        <Typography variant="body2"  align="left" id={`menaDisclaimer${props.id}`} style={{margin:"0.7rem 1.5rem"}} sx ={{margin:'20px 0px', marginLeft:"0.5rem", fontSize:props.isMobile?"0.65rem":"0.9rem",fontFamily:"Amazon Ember", cursor: "pointer" }} gutterBottom>
                            Every job opportunity featured on this website is offered on a contract basis. If you are selected to a job, you will be employed directly by the staffing agency that listed the job. The nature of agency employment allows for the possibility of being assigned to any client of the staffing agency at the agency’s sole discretion.
                        </Typography>
                        <Typography variant="body2" align="left" id={`menaDisclaimer${props.id}`} style={{margin:"0.7rem 1.5rem"}} sx ={{marginBottom:'0px', marginLeft:"0.5rem", fontSize:props.isMobile?"0.65rem":"0.9rem",fontFamily:"Amazon Ember", cursor: "pointer" }} gutterBottom>
                            Every staffing agency featured on this website adheres to fair and equal recruitment practices. No fee/charges are requested from the candidate at any stage of the recruitment/hiring process either overseas or locally.
                        </Typography>
                        <Typography variant="body2" align="left" id={`menaDisclaimer${props.id}`} style={{margin:"0.7rem 1.5rem"}} sx ={{marginBottom:'0px', marginLeft:"0.5rem", fontSize:props.isMobile?"0.65rem":"0.9rem",fontFamily:"Amazon Ember", cursor: "pointer" }} gutterBottom>
                            Every staffing agency featured on this website prohibits forced labor and human trafficking in all our operations and in those of all subcontractors, suppliers and agents in our global supply chain. Workers shall not be subject to any form of forced, compulsory, bonded, or indentured labor.
                            Workers have the right to keep all their personal documents with them at all times. All work must be voluntary, and workers shall have the freedom to terminate their employment at any time without penalty, upon giving reasonable notice.
                        </Typography>
                            <Typography variant="body2"  align="left" id={`menaDisclaimer${props.id}`} style={{margin:"0.7rem 1.5rem"}} sx ={{marginBottom:'0px', marginLeft:"0.5rem", fontSize:props.isMobile?"0.65rem":"0.9rem",fontFamily:"Amazon Ember", cursor: "pointer" }} gutterBottom>
                                Every staffing agency featured on this website is committed to a diverse and inclusive workplace. They provide equal employment opportunities and do not discriminate on the basis of race, origin, gender, gender identity, sexual orientation, disability, age, or other legally protected status.
                        </Typography>
                    </Box>
                }

                <Box mt={5}>
                    <Copyright />
                </Box>
            </Grid>
        </Grid>
    );
}

export default withAlertSnackBar(SignIn);
