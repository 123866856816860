import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ButtonSecondary from "../ButtonSecondary/ButtonSecondary";

export default function ResponsiveDialog(props) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleApply = () => {
        setOpen(false);
        props.handleApply();
    };

    return (
        <div>
            <ButtonPrimary id="dialogBoxResponsive" size="medium" sx={{margin: '0px'}} onClick={handleClickOpen} isDisabled={props.isDialogBoxDisable} Title={props.title}/>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Do you confirm to withdraw your application?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please note - after withdrawing your application, you will not be considered for this job. You can refer the candidate dashboard to access your active applications against other jobs.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonSecondary size="medium" sx={{margin: '0px'}} id="dialogBoxResponsiveDisagree" onClick={handleClose} color="danger" Title="Cancel" ButtonIcon={ <CloseIcon />} />
                    <ButtonPrimary size="medium" sx={{margin: '0px'}} id="dialogBoxResponsiveAgree" onClick={handleApply} autoFocus Title="Continue" ButtonIcon={ <CheckIcon /> } />
                </DialogActions>
            </Dialog>
        </div>
    );
}