import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    textTransform: "none",
  },
}));

function ButtonPrimary(props) {
  const classes = useStyles();
  return (
    <Button
      onClick={props.onClick ? props.onClick : null}
      variant="contained"
      color="primary"
      size= {props.size ? props.size : "large"}
      className={classes.button}
      startIcon={props.ButtonIcon}
      tabIndex={props.tab_index}
      disabled={props.isDisabled}
    >
      {props.Title}
    </Button>
  );
}

export default ButtonPrimary;
