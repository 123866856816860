import { Container, Grid, Typography} from "@mui/material";
import { withAlertSnackBar } from "../../HOComponents/AlertSnackBarHOC";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {useHistory} from "react-router-dom";
import {ActivityServices} from "../../Services/activities";
import constants from "../../Utils/Constants"
import ReactPaginate from 'react-paginate';
import ApplicationCard from "./ApplicationCard";
import Link from "@material-ui/core/Link";
import AlertDialog from "./AlertDialog";
import {sortApplications} from "../../Utils/Comparator";
import {getCandidateHiringStage} from "../../Utils/GridOperations";
import {PhoService} from "../../Services/phoService";
import urlcat from "urlcat";
import {getDMSFeatureFlag} from "../../Utils/DMSConstants";
import {DocumentServices} from "../../Services/documents";
import {isMENACountry} from "../../Utils/CountryUtil";
import * as CountryUtil from "../../Utils/CountryUtil";

function CandidateDashboard(props) {
    let itemsPerPage =10;
    const history = useHistory();
    const [applications, setApplications] = useState([]);
    const [assignedPhos,setAssignedPhos] = useState([]);
    const [currentApplications, setCurrentApplications] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [isAlertActive, setIsAlertActive] = useState(false);
    const [profile, setProfile] = useState({})
    const [alert, setAlert] = useState();
    const params = useParams();
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 768;
    const handleWindowSizeChange=() => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentApplications(applications.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(applications.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, applications]);

    async function getCombinedData() {
        await getJobProfiles()
        await getAssignedPhos()
        await getCandidateProfile()
    }

    useEffect( () => {
        getCombinedData();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(applications.length>0){
            createDocumentAlert()
        }
        if(applications.length>0 && assignedPhos.length>0){
            if(sessionStorage.getItem('assignedPhoJobId')!==null && sessionStorage.getItem('assignedPhoJobId')!==undefined) {
                redirectToPhoPortal(applications,assignedPhos);
            }
        }
        // eslint-disable-next-line
    },[applications,assignedPhos])

    const requestBody= {
        emailId: localStorage.getItem('emailId')
    }

    const redirectToPhoPortal = async (applications,assignedPhos) =>{
        const jobId = sessionStorage.getItem('assignedPhoJobId');
        const jobApplication = applications.find(application => application.jobId === jobId);
        const phoApplication = assignedPhos.find(pho => pho.jobId === jobId);
        if (applications.length !== 0 && assignedPhos.length !== 0 && jobApplication && phoApplication) {
            const createPhoData = {
                phoId: phoApplication.phoId,
                candidateId: localStorage.getItem('emailId'),
                applicationId: jobApplication.applicationId,
                accesstoken: localStorage.getItem('accesstoken'),
                idtoken: localStorage.getItem('idtoken'),
                locale: isMENACountry() ? "en_US" : "",
            }
            let link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.href = urlcat(CountryUtil.getPhoBaseUrl(), "/:site/:role", {
                site: phoApplication.site,
                role: phoApplication.role,
                ...createPhoData
            });
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link)
                sessionStorage.setItem('assignedPhoJobId',null);
            }, 300);
        }
    }

    const getAssignedPhos = async () =>{
        await PhoService.getAssignedPhoByCandidateId({candidateId:requestBody.emailId}).then(res=>{
            let assignedPhosL = (res && res.data && res.data.phoAssignments) || [];
             setAssignedPhos(assignedPhosL.map(pho=> {return{phoId: pho.phoId,jobId: pho.jobId,site:pho.site,role:pho.role,applicationId:pho.applicationId}}));
         }).catch(err=>{
            props.snackbarShowMessage(
                `${constants.ERROR.PREPOPULATE_ERROR} ${err?.data?.message}`,
                "error",
                "4000",
                err?.data?.message
            );
        })

    }

    const getCandidateProfile = async () => {
        await ActivityServices.getCandidateProfileByEmail({email:requestBody.emailId}).then(res => {
            const candProfile = res && res.data
            setProfile(candProfile)
        }).catch(err => {
            props.snackbarShowMessage(
                `${constants.ERROR.PROFILE_ERROR} ${err?.data?.message}`,
                "error",
                "4000",
                err?.data?.message
            );
        });
    }

    const getJobProfiles = async () =>{
        await ActivityServices.getApplicationsByCandidate(requestBody)
            .then((res) => {
                let rows = (res && res.data && res.data.applications) || [];
                let rowsData = []
                if (rows ){
                    rows.forEach(row => {
                            rowsData.push({
                                jobId:row.jobId,
                                jobRole:row.jobRole,
                                vendorName:row.agency,
                                city:row.city,
                                hiringStage:getCandidateHiringStage(row.hiringStage),
                                applicationId: row.applicationId,
                                jobStatus: row.jobStatus,
                                refId: row.refId,
                                vendorId: row.vendorId,
                                startDate: row.startDate
                            })
                    })
                }
                rowsData= sortApplications(rowsData, constants.DASHBOARD.HIRING_STAGE)
                setApplications(rowsData)
            })
            .catch((res, error) => {
                props.snackbarShowMessage(
                    `${constants.ERROR.PREPOPULATE_ERROR} ${res.data.message}`,
                    "error",
                    "5000",
                    res.data.message
                );
            });
    }

    useEffect(() => {
        if(params && params.jobId && applications){
            const application = applications.find(application => application.jobId === params.jobId)
            if(application && constants.DOCUMENTS.OFFER_LETTER_STAGES.includes(application.hiringStage) && constants.JOB_STATUS.ACTIVE_STATUSES.includes(application.jobStatus)  ){
                history.push({pathname:constants.DOCUMENTS.VIEW, state:{
                        documentType: constants.DOCUMENTS.HIRING_STAGE_DOCUMENT_TYPE_MAP[application.hiringStage],
                        application: application}})
            }
            else if(application){
                props.snackbarShowMessage(constants.ERROR.OFFER_LETTER_INVALID, "error", "5000");
            }
        }
        // eslint-disable-next-line
    },[applications])

    const createDocumentAlert = () => {
       const alertContent =  applications.map((application) => {
            if((constants.DOCUMENTS.ALERT_STATUSES.includes(application.hiringStage)) && !(constants.JOB_STATUS.DISCONTINUE_STATUSES.includes(application.jobStatus))){
                setIsAlertActive(true)
                return (
                    createAlertLink(application)
                )}
            return ""
            })
        setAlert(alertContent)
    }

    const redirectToPreviewPage = async (application) => {
        if(getDMSFeatureFlag()){
            let docMapDict = {}
            let body = {
                candidateIds: [application.applicationId],
                userType: "CANDIDATE",
            }
            let candidateDocuments = await DocumentServices.getDocuments(body);
            candidateDocuments.data.documents.forEach(doc => {
                docMapDict[constants.DOCUMENTS.DMS_DOCUMENT_TYPE_MAP[doc.documentType]]= doc
            })
            let docType = constants.DOCUMENTS.HIRING_STAGE_DOCUMENT_TYPE_MAP[application.hiringStage]
            history.push({pathname:constants.DOCUMENTS.VIEW, state:{
                    documentType: docType,
                    application: application,
                    documentData: docMapDict[docType]}})
            return;
        }
        history.push({pathname:constants.DOCUMENTS.VIEW, state:{
                    documentType: constants.DOCUMENTS.HIRING_STAGE_DOCUMENT_TYPE_MAP[application.hiringStage],
                    application: application}})
    }

    const createAlertLink = (application) => {
        return (
            <li>
                {`${application.jobId} - `}
                <Link sx={{paddingLeft:"10px"}} className="primary_color"
                      onClick={async () => {
                          await redirectToPreviewPage(application)
                      }
                }>
                    {`${constants.DOCUMENTS.HIRING_STAGE_DOCUMENT_TYPE_MAP[application.hiringStage]}`}
                </Link>
            </li>
        )
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % applications.length;
        setItemOffset(newOffset);
    };

    return (
        < >
            <Container sx={{ maxWidth:'90%' }} maxWidth={false}>
                <Grid container id='application-container'>
                    <Typography sx={{ fontSize: 25, margin:'10px'  }} >Applications</Typography>

                    {isAlertActive && !isMENACountry() &&  <AlertDialog isMobile={isMobile} alert={alert}/>}

                    <Grid width={"100%"}>
                        {
                            isMENACountry() && <Typography id={`menaDisclaimer${props.id}`} sx ={{marginBottom:'1rem', marginLeft:"0.5rem", fontSize:props.isMobile?"0.65rem":"0.9rem",fontFamily:"Amazon Ember",color:"#dc4c64", cursor: "pointer" }} gutterBottom>
                                The staffing agency that is listing the job will be your direct employer. During the selection process, the recruitment team of the staffing agency will provide detailed information about your pay and benefits.
                                They will also send to your personal email the detailed job offer, including exact start dates and shift schedules. After receiving this information, associates will then follow the agency instructions to
                                perform their job duties at client sites to be determined by the staffing agency at their sole discretion.
                            </Typography>
                        }
                    </Grid>

                    {currentApplications && currentApplications.map((application, index) => (
                        <ApplicationCard isMobile={isMobile} application = {application} profile={profile} pho={assignedPhos && assignedPhos.find(pho=>pho.applicationId===application.applicationId)} id={application.applicationId}></ApplicationCard>
                    ))}

                </Grid>
            </Container>
            <Grid container sx={{marginBottom:"60px"}} justifyContent={"center"}>
                <ReactPaginate
                    containerClassName={"reactPaginate"}
                    activeClassName={"selectedPage"}
                    previousClassName={"previousPage"}
                    nextClassName={"nextPage"}
                    disabledClassName={"disableButton"}
                    breakClassName={"paginationBreak"}
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< "
                    renderOnZeroPageCount={null}
                />
            </Grid>

        </>
    );
}

export default withAlertSnackBar(CandidateDashboard);