import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {Card, Grid, Typography} from "@mui/material";
import job_pic from "../../assets/images/job_pic.png";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import constants, {VENDOR_SUBDOMAIN_MAPPING} from "../../Utils/Constants";
import {useHistory} from "react-router-dom";
import ResponsiveDialog from "../../components/DialogBox/ResponsiveDialog";
import {ActivityServices} from "../../Services/activities";
import urlcat from "urlcat";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {isMENACountry} from "../../Utils/CountryUtil";
import * as CountryUtil from "../../Utils/CountryUtil";

function ApplicationCard(props){
    const history = useHistory();
    const jobDetailsActivity = (jobId, vendorId) => {
       const vendorSubdomain = VENDOR_SUBDOMAIN_MAPPING[vendorId]
        if(jobId && jobId.startsWith("JOB-IN-")){
            window.open(`${sessionStorage.getItem('careerSiteURL')}/app#/jobDetail?jobId=${jobId}&locale=en-IN`.replace("vendorid",vendorSubdomain),"_blank")
        }
        else{
            history.push(`${constants.JOB_DETAILS.ROUTE}/${jobId}`)
        }

    }

    const documentsActivity = (application) => {
        history.push({pathname:`${constants.DOCUMENTS.ROUTE}/${application.jobId}`,
        state:{application : application}})
    }

    const handleWithdrawalActivity = () => {

        let body = {
            candidatesInput:[{
                candidateId:props.application.applicationId,
                hiringStage: constants.DOCUMENTS.STATUS.APPLICATION_WITHDRAWN,
                operationType: constants.DOCUMENTS.OPERATION_TYPE.APPLICATION_WITHDRAWN,
                email:localStorage.getItem('emailId')

            }],
            jobId: props.application.jobId,
            modifiedBy:localStorage.getItem('emailId'),
            actionType: constants.DOCUMENTS.ACTION_TYPE.DELETE_CANDIDATE
        }
        ActivityServices.candidateActionRequest(body)
            .then((res) => {
                const status = res && res.data && res.data.candidatesOutput[0] && res.data.candidatesOutput[0].status
                if(status === "Success"){
                    props.snackbarShowMessage(
                        `${constants.CRUD_SUCCESS_ALERTS.WITHDRAWAL}`
                    );
                    window.setTimeout(() => {
                        window.location.reload();
                    }, 1000)

                }
                else{
                    props.snackbarShowMessage(
                        `${constants.ERROR.APPLICATION_WITHDRAWN_ERROR} ${res.data.message}`,
                        "error",
                        "5000",
                        res.data.message
                    );
                }

            })
            .catch((res, error) => {
                props.snackbarShowMessage(
                    `${constants.ERROR.APPLICATION_WITHDRAWN_ERROR} ${res.data.message}`,
                    "error",
                    "5000",
                    res.data.message
                );
            });
    }

    const getButtonTitle = (hiringStage) => {
        const title = constants.DOCUMENTS.BUTTON_TITLE_MAP[hiringStage]
        if(title !== undefined )
            return title
       return constants.DOCUMENTS.TITLE
    }

    const getCardColor = (status) => {
        if(constants.JOB_STATUS.GREY_STATUSES.includes(status) || props.profile.rehireFlag === constants.REHIRE_FLAGS.N){
            return "#f2f2f2";
        }
        return ""
    }

    const createPhoData = {
        phoId: props.pho && props.pho.phoId,
        candidateId: localStorage.getItem('emailId'),
        applicationId:props.application && props.application.applicationId,
        locale: isMENACountry() ? "en_US" : "",
        accesstoken: localStorage.getItem('accesstoken'),
        idtoken: localStorage.getItem('idtoken'),
    };

    return (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card id={`applicationCard${props.id}`} sx={{ minWidth: 180, borderRadius: 1, borderColor:"#000000", marginBottom: "15px",padding:"20px",backgroundColor:getCardColor(props.application.jobStatus) }} variant="outlined" >
                    <Grid container justifyContent="center">
                        {props.application.jobStatus === constants.JOB_STATUS.APPLICATION_WITHDRAWN &&
                            <Typography id={`hiringStageText${props.id}`} sx ={{marginBottom:'0px', fontSize:props.isMobile?"0.95rem":"1.1rem",fontFamily:"Amazon Ember", color:"#ff4d4d"}}  gutterBottom>
                                {constants.TEXTS.WITHDRAWN_TEXT}
                            </Typography>}
                        { (constants.JOB_STATUS.DISCONTINUE_STATUSES.includes(props.application.jobStatus) || props.profile.rehireFlag === constants.REHIRE_FLAGS.N)&&
                            <Typography id={`hiringStageText${props.id}`} sx ={{marginBottom:'0px', fontSize:props.isMobile?"0.95rem":"1.1rem",fontFamily:"Amazon Ember", color:"#ff4d4d"}}  gutterBottom>
                                {`${constants.TEXTS.DISCONTINUE_TEXT} ${props.application.vendorName}`}
                            </Typography>}
                    </Grid>
                    <Grid direction="row" wrap='nowrap' gap={props.isMobile?"1rem":"3rem"}  container >

                        <Grid item height={props.isMobile?"120px":"140px"} width={props.isMobile?"120px":"140px"} minWidth={props.isMobile?"120px":"140px"} sx={{backgroundColor:"#e6f2ff", borderRadius: 2}}  >
                            <img height="100%" width="100%" alt=" " className="image" src={job_pic}/>
                        </Grid>

                        <Grid  item>
                            <Typography id={`jobRoleText${props.id}`} className="jobDetailText"
                                         sx={{fontSize:props.isMobile?"0.925rem":"1.2rem", fontFamily:"Amazon Ember"}}  gutterBottom>
                                {props.application.jobRole}
                            </Typography>
                            {!isMENACountry() && <Typography id={`jobIdText${props.id}`} gutterBottom sx={{fontSize:props.isMobile?"0.875rem":"1.1rem",  fontFamily:"Amazon Ember"}}>
                                {`Job Id: ${props.application.jobId}`}
                            </Typography> }
                            <Typography id={`startDateText${props.id}`} sx ={{marginBottom:'0px', fontSize:props.isMobile?"0.875rem":"1.1rem",fontFamily:"Amazon Ember"}}  gutterBottom>
                                {`Start Date: ${props.application.startDate}`}
                            </Typography>
                            <Typography id={`vendorText${props.id}`}  gutterBottom sx={{fontSize:props.isMobile?"0.875rem":"1.1rem",fontFamily:"Amazon Ember"}}>
                                {`Agency: ${props.application.vendorName}`}
                            </Typography>
                            <Typography id={`cityText${props.id}`} sx ={{marginBottom:'0px', fontSize:props.isMobile?"0.875rem":"1.1rem",fontFamily:"Amazon Ember"}}  gutterBottom>
                                {`Location: ${props.application.city}`}
                            </Typography>
                            <Typography id={`hiringStageText${props.id}`} sx ={{marginBottom:'0px', fontSize:props.isMobile?"0.875rem":"1.1rem",fontFamily:"Amazon Ember"}}  gutterBottom>
                                {`Hiring Stage: ${props.application.hiringStage}`}
                            </Typography>
                        </Grid>

                    </Grid>
                    <Grid container direction="row" alignItems="center" >
                        <Grid display={"flex"} flexGrow={"1"}>
                            {props.pho && <button style={{padding:"0.5rem",paddingTop:"0.2rem",backgroundColor:"#dc4c64",borderRadius:"10px"}}><a href={urlcat(CountryUtil.getPhoBaseUrl(),"/:site/:role", {site:props.pho.site,role:props.pho.role,...createPhoData})} target="_blank" rel="noreferrer"  style={{display:"flex",textDecoration:"none",color:"#ffffff",alignItems:"flex-end"}}><NavigateNextIcon/>Click here to complete Pre-hire Orientation(PHO) training</a></button>}
                        </Grid>
                        {!isMENACountry() && <ButtonPrimary isDisabled={!(constants.DOCUMENTS.DOCUMENT_EXISTS_STAGES.includes(props.application.hiringStage)) || props.application.jobStatus === constants.JOB_STATUS.DELETED || props.profile.rehireFlag === constants.REHIRE_FLAGS.N}
                                       id={`btnDocuments${props.id}`} onClick={() =>documentsActivity(props.application)} size="medium" sx={{margin: '0px'}} Title={getButtonTitle(props.application.hiringStage)}/>}
                        {!isMENACountry() &&  <ButtonPrimary id={`btnJobDetails${props.id}`} onClick={() =>jobDetailsActivity(props.application.jobId,props.application.vendorId)} size={"medium"} sx={{margin: '0px'}} Title="Job Details"/>}
                        <ResponsiveDialog title="Withdraw Application" handleApply={handleWithdrawalActivity} isDialogBoxDisable={props.application.hiringStage === constants.DOCUMENTS.STATUS.OFFER_LETTER_ACCEPTED
                            || constants.JOB_STATUS.DISCONTINUE_STATUSES.includes(props.application.jobStatus) || props.application.jobStatus === constants.JOB_STATUS.APPLICATION_WITHDRAWN || props.profile.rehireFlag === constants.REHIRE_FLAGS.N } />
                    </Grid>

                </Card>

            </Grid>
    );
}

export default withAlertSnackBar(ApplicationCard)