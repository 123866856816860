export const PHO_PORTAL_BASE_URL = {
    alpha: {
        AE : "https://alpha.learnings.warehousejobsuae.com",
        SA : "https://alpha.learnings.warehousejobsksa.com",
        EG : "https://alpha.learnings.warehousejobseg.com",
        IN : "https://learnings.alpha.hvhindia.in/"
    },
    beta: {
        SA : "https://beta.learnings.warehousejobsksa.com",
        EG : "https://beta.learnings.warehousejobseg.com",
        AE : "https://beta.learnings.warehousejobsuae.com",
        IN : "https://learnings.beta.hvhindia.in/"
    },
    gamma: {
        SA : "https://gamma.learnings.warehousejobsksa.com",
        AE : "https://gamma.learnings.warehousejobsuae.com",
        EG : "https://gamma.learnings.warehousejobseg.com",
        IN : "https://learnings.gamma.hvhindia.in/"
    },
    prod: {
        SA : "https://learnings.warehousejobsksa.com",
        AE : "https://learnings.warehousejobsuae.com",
        EG : "https://learnings.warehousejobseg.com",
        IN : "https://learnings.hvhindia.in/"
    }
}


export let isMENACountry = () =>{
    const country = sessionStorage.getItem('countryCode');
    return country === "AE" || country === "EG" || country === "SA";
}

export const getPhoBaseUrl = () =>{
    return PHO_PORTAL_BASE_URL[sessionStorage.getItem('stage')][sessionStorage.getItem('countryCode')]
}