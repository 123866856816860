import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, InputAdornment, Input} from "@material-ui/core";
import InputLabel from "../../components/FormPage/InputLabel";
import {FormPasswordMismatchError, Formvalidationerror} from "../../components/FormPage/FormValidationError";
import Formfieldstar from "../../components/FormPage/FormFieldStar";
import {validatePattern} from "../../Utils/Validator";
import constants from "../../Utils/Constants";
import InfoHelpIcon from "../../components/FormPage/InfoHelpIcon";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {UserServices} from "../../Services/users";
import {handleCognitoException} from "../../Utils/Exceptions";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function ResetPasswordDialog(props) {
    const theme = useTheme();
    useMediaQuery(theme.breakpoints.down('md'));
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const classes = useStyles();

    const [newPasswordValues, setNewPasswordValues] = React.useState({
        password: '',
        showPassword: false,
    });
    const [confirmPasswordValues, setConfirmNewPasswordValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const [oldPasswordValues, setOldPasswordValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const resetFormValues = () => {
        newPasswordValues.password = '';
        newPasswordValues.showPassword = false;
        confirmPasswordValues.password = '';
        confirmPasswordValues.showPassword = false;
        oldPasswordValues.password = false;
        oldPasswordValues.showPassword = '';
        setIsFormSubmitted(false);
        if(props.setIsResetPasswordRequired) {
            props.setIsResetPasswordRequired(false);
        }
        if(props.setChangePassword) {
            props.setChangePassword(false);
        }
        if(props.setIsFormSubmitted) {
            props.setIsFormSubmitted(false);
        }
        if(props.formData) {
            props.formData.userName = '';
            props.formData.password = '';
        }
    }

    const cognitoResetPassword = async () => {
        let body = {
            userName: props.changePassword ? null : props.formData.userName,
            oldPassword: props.changePassword ? oldPasswordValues.password : props.formData.password,
            newPassword: newPasswordValues.password,
            accessToken: props.changePassword ? localStorage.getItem('accesstoken') : null
        };

        if(props.changePassword) {
            await UserServices.resetPassword(body)
                .then((data)=>{
                    if(data.data.error) {
                        if(data.data.error.code === 'NotAuthorizedException') {
                            props.snackbarShowMessage(`${constants.AUTH.OLD_PASSWORD_INVALID}`, "error", "10000");
                        }
                        else {
                            props.snackbarShowMessage(`${handleCognitoException(data.data.error.code)}`, "error", "10000");
                        }
                    }
                    else {
                        resetFormValues();
                        props.snackbarShowMessage(`${constants.AUTH.PASSWORD_CHANGE_SUCCESS}`, "success", "10000")
                    }
                })
                .catch((err)=>{
                    props.snackbarShowMessage(`${constants.AUTH.PASSWORD_RESET_FAILED}`,"error", "10000");
                })
        }
        else {
            await UserServices.resetTempPassword(body)
                .then((data)=>{
                    const userData = data.data;
                    if(!userData.login) {
                        props.snackbarShowMessage(`${handleCognitoException(userData.error.code)}`, "error", "10000");
                    }
                    else {
                        resetFormValues();
                        setLoginAndRedirect(userData)
                        props.snackbarShowMessage(`${constants.AUTH.SIGNIN_SUCCESS}`);
                    }
                })
                .catch((err)=>{
                    props.snackbarShowMessage(`${constants.AUTH.PASSWORD_RESET_FAILED}`,"error");
                })
        }


    }

    const validateForm = () => {
        return (props.changePassword ? oldPasswordValues.password : true) && newPasswordValues.password &&
            confirmPasswordValues.password && validatePattern(new RegExp(constants.PATTERN.PASSWORD_PATTERN), newPasswordValues.password) &&
            newPasswordValues.password === confirmPasswordValues.password;
    }

    const setLoginAndRedirect = async (userData) => {
        try {
            localStorage.setItem("session_user", userData.userName.split('@')[0]);
            localStorage.setItem("accesstoken", userData.accesstoken);
            localStorage.setItem("idtoken", userData.idtoken);
            localStorage.setItem("refreshtoken", userData.refreshtoken);
            localStorage.setItem("candidateId", userData.candidateId)
            localStorage.setItem("emailId", userData.userName)

            props.snackbarShowMessage(`${constants.AUTH.SIGNIN_SUCCESS}`);
            if(localStorage.getItem("location")){
                window.setTimeout(() => {
                    window.location.href = localStorage.getItem("location");
                }, 1000);                }
            else{
                window.setTimeout(() => {
                    window.location.href = `${constants.DASHBOARD.ROUTE}`;
                }, 1000);
            }
        } catch(error)
        {
            props.snackbarShowMessage(`${constants.AUTH.SIGNIN_FAILED}`);
        }
    };

    const handleConfirm = async () => {
        setIsFormSubmitted(true);
        if(validateForm()) {
            await cognitoResetPassword()
        }
    }

    const handleCancel = () => {
        resetFormValues()
    }

    const handlePasswordChange = (prop) => (event) => {
        setNewPasswordValues({ ...newPasswordValues, [prop]: event.target.value });
    };

    const handleConfirmPasswordChange = (prop) => (event) => {
        setConfirmNewPasswordValues({ ...confirmPasswordValues, [prop]: event.target.value });
    };

    const handleOldPasswordChange = (prop) => (event) => {
        setOldPasswordValues({ ...oldPasswordValues, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        document.getElementById('newPassword').focus();
        setNewPasswordValues({
            ...newPasswordValues,
            showPassword: !newPasswordValues.showPassword,
        });
    };

    const handleClickShowConfirmPassword = () => {
        document.getElementById('confirmPassword').focus();
        setConfirmNewPasswordValues({
            ...confirmPasswordValues,
            showPassword: !confirmPasswordValues.showPassword,
        });
    };

    const handleClickShowOldPassword = () => {
        document.getElementById('oldPassword').focus();
        setOldPasswordValues({
            ...oldPasswordValues,
            showPassword: !oldPasswordValues.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const checkValidationPattern = (elementId,regex,data,error) => {
        if(data) {
            if(!validatePattern(regex, data) ){
                document.getElementById(elementId).innerHTML = error
            }else{
                document.getElementById(elementId).innerHTML = ''
            }
        }
    }

    const handleValidationError = (elementId) => {
        switch (elementId) {
            case 'NewPasswordError' :
                checkValidationPattern(elementId, new RegExp(constants.PATTERN.PASSWORD_PATTERN), newPasswordValues.password, 'Invalid Password Format');
                break;
            default :
        }
    }

    const removeValidationError = (elementId) => {
        setIsFormSubmitted(false)
        document.getElementById(elementId).innerHTML = ''
    }

    const passwordMatchCheck = (elementId) => {
        if(confirmPasswordValues.password && newPasswordValues.password !== confirmPasswordValues.password) {
            document.getElementById(elementId).innerHTML = 'Passwords do not match';
        }
    }
    return (
        <div>
            <Dialog
                 open={true}>
                <MuiDialogTitle disableTypography className={classes.root}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleCancel}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h5" style = {{marginLeft: "2rem"}}>{props.changePassword ? "Change your password" : "Reset your password"}</Typography>
                        <p style = {{fontSize: "1rem", marginTop: "1rem", marginLeft: "2rem"}}>In order to protect your account, make sure your password:</p>
                        <ul style = {{fontSize: "1rem", marginTop: "1rem", marginLeft: "1rem"}}>
                            <li>Has atleast 8 characters</li>
                            <li>A mixture of both uppercase and lowercase letters</li>
                            <li>A mixture of letters and numbers</li>
                            <li>Inclusion of at least one special character</li>
                        </ul>
                        <Grid container alignItems="top" className="form_group_list">
                            { props.changePassword && <Grid item xl={12} lg={12} md={12} sm={12} xs={10}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                        <InputLabel Title="Old Password:" />
                                        <Formfieldstar/>
                                        <InfoHelpIcon
                                            FieldToolTip="Password should be atleast 8 characters long and should contain atleast one small letter,capital letter,number and a special character"
                                            IconClassName={<InfoOutlinedIcon />}
                                            Title="Old Password"
                                        />
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                                        <Input
                                            id="oldPassword"
                                            style = {{width: '15rem'}}
                                            type={oldPasswordValues.showPassword ? 'text' : 'password'}
                                            value={oldPasswordValues.password}
                                            onChange={handleOldPasswordChange('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowOldPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {oldPasswordValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        {isFormSubmitted && !oldPasswordValues.password && (
                                            <Formvalidationerror fieldName = "Old password"/>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid> }

                            <Grid item xl={12} lg={12} md={12} sm={12} xs={10}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                        <InputLabel Title="New Password:" />
                                        <Formfieldstar/>
                                        <InfoHelpIcon
                                            FieldToolTip="Password should be atleast 8 characters long and should contain atleast one small letter,capital letter,number and a special character"
                                            IconClassName={<InfoOutlinedIcon />}
                                            Title="New Password"
                                        />
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                                        <Input
                                            id="newPassword"
                                            style = {{width: '15rem'}}
                                            type={newPasswordValues.showPassword ? 'text' : 'password'}
                                            value={newPasswordValues.password}
                                            onChange={handlePasswordChange('password')}
                                            onClick = {()=> removeValidationError('NewPasswordError')}
                                            onBlur = {() => handleValidationError('NewPasswordError')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {newPasswordValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        <small><p id='NewPasswordError' className='amz_field_error'></p></small>
                                        {isFormSubmitted && !newPasswordValues.password && (
                                            <Formvalidationerror fieldName = "New password"/>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                        <InputLabel Title="Confirm New Password:" />
                                        <Formfieldstar/>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Input
                                            id="confirmPassword"
                                            style = {{width: '15rem'}}
                                            type={confirmPasswordValues.showPassword ? 'text' : 'password'}
                                            value={confirmPasswordValues.password}
                                            onChange={handleConfirmPasswordChange('password')}
                                            onClick = {()=> removeValidationError('ConfirmPasswordError')}
                                            onBlur = {() => passwordMatchCheck('ConfirmPasswordError')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {confirmPasswordValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        <small><p id='ConfirmPasswordError' className='amz_field_error'></p></small>
                                        {isFormSubmitted && !confirmPasswordValues.password && (
                                            <Formvalidationerror fieldName = "Confirm New Password"/>
                                        )}
                                        {isFormSubmitted && !confirmPasswordValues.password && !newPasswordValues.password && newPasswordValues.password!==confirmPasswordValues.password &&
                                        (
                                            <FormPasswordMismatchError/>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonPrimary variant="outlined" Title={props.changePassword ? "Confirm" : "Confirm and Sign In"} autoFocus onClick={handleConfirm} color="primary" ButtonIcon={ <CheckIcon /> } />
                </DialogActions>
            </Dialog>
        </div>
    )
}

ResetPasswordDialog.propTypes = {

}

export default ResetPasswordDialog