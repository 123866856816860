import React from 'react'
import PropTypes from 'prop-types'
import {isMENACountry} from "../../Utils/CountryUtil";

function Title(props) {
    return (
        <> {isMENACountry() ? <b>Warehouse Jobs Candidate Portal</b> : <b>{props.title}</b> }</>
    )
}

Title.defaultProps = {
    title: 'HVH Candidate Portal',
}

Title.propTypes = {
    title: PropTypes.string,
}

export default Title
