import React, {useState, useEffect} from 'react'
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: '#ffffff',
        backgroundColor: '#007980',
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      color: '#ffffff',
        backgroundColor: '#007980',
    },
  }));
  
function UserAvatar() {
    const classes = useStyles();
    const [userdetails, setUserDetails] = useState('');

    useEffect(() => {
  
      const sessionUser = localStorage.getItem('session_user');
 
      if (sessionUser) {
        setUserDetails(sessionUser);
      }
 
    }, []);

    return (
        
        <div className={classes.root}>
            <Avatar className={classes.small}> {userdetails ? userdetails.charAt(0):''} </Avatar>
    </div>
    )
}

export default UserAvatar
