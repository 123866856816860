import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    textTransform: "none",
  },
}));

function ButtonSecondary(props) {
  const classes = useStyles();

  return (
    <Button
      style={props.style}
      disabled={props.isDisabled}
      onClick={props.onClick ? props.onClick : null}
      variant="outlined"
      color="primary"
      size="large"
      className={classes.button}
      startIcon={props.ButtonIcon}
      tabIndex={props.tab_index}
    >
      {props.Title}
    </Button>
  );
}

export default ButtonSecondary;
